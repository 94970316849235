:root {
    --spinner-color: #3498db;
    --spinner-border-color: rgba(52, 152, 219, 0.1);
    --spinner-size: 40px;
    --spinner-border-thickness: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .spinner {
    border: var(--spinner-border-thickness) solid var(--spinner-border-color);
    border-left-color: var(--spinner-color);
    border-radius: 50%;
    width: var(--spinner-size);
    height: var(--spinner-size);
    animation: spin 1s ease-in-out infinite;
  }
  
  @keyframes spin {
    0% { 
        transform: rotate(0deg); 
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    100% { 
        transform: rotate(360deg);
        opacity: 1;
    }
  }
  