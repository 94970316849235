/* EmailSubscription.css */

div {
    margin: 10px;
    padding: 20px;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
  }
  
  h2 {    
    display: flex;
    place-items: center;
    font-size: 1.5em;
    margin-bottom: 10px;
    justify-content: center;
  }

  .icon-container {
    display: flex;
    justify-content: center;
    gap: 50px; /* Adjust gap between icons */
    margin-bottom: 10px; /* Adjust margin as needed */
  }

  .icon-container img {
    width: 40px; /* Adjust icon size as needed */
    height: auto;
  }
  
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
   img{
        height: 100px;
        display: flex;
        margin-top: -20px;
        align-items: center;
        justify-content: center;
   }


  input[type="email"] {
    padding: 10px;
    width: 100%;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background: linear-gradient(to right, #007BFF, #0056b3);
  }
  
  button:hover {
    background: linear-gradient(to right, #0056b3, #003a7a);
  }
  
  p {
    margin-top: 10px;
    color: #333;
  }
  a {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  /* Mobile-specific styles */
  @media (max-width: 768px) {
    div {
      margin: 0px;
      padding: 0px;
    }
  
    h2 {
      font-size: 1em;
    }
  
    input[type="email"] {
      width: 95%;
    }
  
    button {
      padding: 8px 16px;
    }
  }
  