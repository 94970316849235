.container {
  width: 100%;
  display: grid;
  place-items: center;
  margin: 10px auto;
  background: linear-gradient(to right, #dddddd, #9593fe);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding-top: 70px;
}

/* Mobile-specific styles */
@media (max-width: 870px) {
  .container {
    width: 100%;
  }

  .icon-container {
    display: flex;
    justify-content: center;
    gap: 50px; /* Adjust gap between icons */
    margin-top: 40px; /* Adjust margin as needed */
  }

  .customer-table, .tokens-table {
    width: 50%; /* Set to 100% to take the full width of its container */
    max-width: 200px; /* Set a maximum width to restrict the table size */
    border-collapse: collapse;
    margin-bottom: 20px;
    margin-left: 10px;
  }
  
  .customer-table td, .tokens-table td, .tokens-table th {
    border: 1px solid #E0E0E0;
    padding: 3px;
    word-wrap: break-word; /* Allows the words to break and move to the next line */
    max-width: 100px; /* Set a max-width to control the width of the cells */
    overflow: hidden; /* Ensures the content is not overflowing the cell */
    text-overflow: ellipsis; /* Adds an ellipsis when the text overflows */
    white-space: nowrap; /* Keeps the text in a single line */
    font-size: 10.5px;
  }

  h2{
    margin-bottom: 10px;
  }

  .search-input {
    width: 50%;
  }
}
h1{
  margin-top: 0px;
}

.search-box {
  margin-top: 0px;
  padding-top: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.search-input {
  padding: 10px;
  width: 300px;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
}

.search-button {
  padding: 10px 20px;
  margin-top: 10px;
  background: linear-gradient(to right, #4CAF50, #45a049);
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.search-button:hover {
  background: linear-gradient(to right, #45a049, #388e3c);
}

.results {
  margin-top: 20px;
}

.customer-table, .tokens-table {
  width: 800px;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.customer-table td, .tokens-table td, .tokens-table th {
  border: 1px solid #E0E0E0;
  padding: 6px;
}

.tokens-table tr:nth-child(even) {
  background-color: rgba(72, 239, 128, 0.1);
}

.tokens-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background: linear-gradient(to right, #4CAF50, #45a049);
  color: white;
}
